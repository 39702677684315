.icon-wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
}
.icon {
  height: 3rem;
}

.icon.node {
  height: 1.8rem;
}

.icon.sls {
  height: 1.6rem;
}

.icon.meteor {
  height: 2rem;
}

.icon.mongo {
  height: 2.8rem;
}

.icon.dynamo {
  height: 2.7rem;
}

.icon.rotation {
  animation: App-logo-spin infinite 20s linear;
}
