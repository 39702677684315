:root {
  /*
  --color-primary: #dbbd00;
  --color-primary-light: #FFDC00;
  --color-primary-dark: #dbbd55;
  */
  --color-primary: #00ffdf;
  --color-primary-light: #00ffdf;
  --color-primary-dark: #00ffdf;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;

  --color-white: #fff;
  --color-black: #000;

  --color-theme-primary: #00ffdf;
  --color-theme-grey: #3e3d42;
  --color-theme-grey-dark: #232227;

  --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
  --shadow-light: 0 2rem 5rem rgba(0,0,0,.1);

  --line: 1px solid var(--color-grey-light-2);

  /* breakpoint variables */
  /*
  --bp-small: 380px;
  --bp-medium: 700px;
  --bp-large: 964px;
  --bp-xlarge: 1200px;
  */
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px, 10px/16px = 62.5% */
}

body {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #3e3d42;
  color: var(--color-theme-grey);
  background-color: #232227;
  background-color: var(--color-theme-grey-dark);
}

a {
  text-decoration: none;
  color: inherit;
}

.icon-wrapper {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.icon {
  height: 3rem;
}

.icon.node {
  height: 1.8rem;
}

.icon.sls {
  height: 1.6rem;
}

.icon.meteor {
  height: 2rem;
}

.icon.mongo {
  height: 2.8rem;
}

.icon.dynamo {
  height: 2.7rem;
}

.icon.rotation {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
}

